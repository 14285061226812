import "./style.css";
import * as THREE from "three";
import * as dat from "dat.gui"
import {GPUComputationRenderer} from 'three/examples/jsm/misc/GPUComputationRenderer.js'

const sizes ={
    width: window.innerWidth,
    height: window.innerHeight
}
const pointer ={
    x:0.0,
    y:0.0,
    down:-1
}
//const gui = new dat.GUI()
var canvas = document.querySelector("canvas.webgl")
var clock = new THREE.Clock();
clock.start()

var camera=new THREE.OrthographicCamera( -1, 1, 1, -1, 0, 1 );


var renderer = new THREE.WebGLRenderer({
    canvas:canvas,
    antialias:true
})
renderer.setSize(sizes.width,sizes.height)
var scene = new THREE.Scene()

const WIDTH = 1080;

var gpuCompute = new GPUComputationRenderer(WIDTH,WIDTH,renderer)

if ( renderer.capabilities.isWebGL2 === false ) {
    gpuCompute.setDataType( THREE.HalfFloatType );
}

const   fragmentNoiseShader = `
#define PI 3.1415926535897932384626433832795
#define TWO_PI 6.283185307179586476925286766559
uniform sampler2D   tex0;
uniform float time;
uniform vec3 u_pointer;

float rand(vec2 co){return fract(sin(dot(co.xy ,vec2(12.9898,78.233))) * 43758.5453);}
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

float cnoise(vec3 P){
  vec3 Pi0 = floor(P); // Integer part for indexing
  vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
  Pi0 = mod(Pi0, 289.0);
  Pi1 = mod(Pi1, 289.0);
  vec3 Pf0 = fract(P); // Fractional part for interpolation
  vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = Pi0.zzzz;
  vec4 iz1 = Pi1.zzzz;

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);

  vec4 gx0 = ixy0 / 7.0;
  vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
  gx0 = fract(gx0);
  vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
  vec4 sz0 = step(gz0, vec4(0.0));
  gx0 -= sz0 * (step(0.0, gx0) - 0.5);
  gy0 -= sz0 * (step(0.0, gy0) - 0.5);

  vec4 gx1 = ixy1 / 7.0;
  vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
  gx1 = fract(gx1);
  vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
  vec4 sz1 = step(gz1, vec4(0.0));
  gx1 -= sz1 * (step(0.0, gx1) - 0.5);
  gy1 -= sz1 * (step(0.0, gy1) - 0.5);

  vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
  vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
  vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
  vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
  vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
  vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
  vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
  vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

  vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
  g000 *= norm0.x;
  g010 *= norm0.y;
  g100 *= norm0.z;
  g110 *= norm0.w;
  vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
  g001 *= norm1.x;
  g011 *= norm1.y;
  g101 *= norm1.z;
  g111 *= norm1.w;

  float n000 = dot(g000, Pf0);
  float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
  float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
  float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
  float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
  float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
  float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
  float n111 = dot(g111, Pf1);

  vec3 fade_xyz = fade(Pf0);
  vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
  vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
  float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
  return 2.2 * n_xyz;
}

float hue2rgb(float f1, float f2, float hue) {
    if (hue < 0.0)
        hue += 1.0;
    else if (hue > 1.0)
        hue -= 1.0;
    float res;
    if ((6.0 * hue) < 1.0)
        res = f1 + (f2 - f1) * 6.0 * hue;
    else if ((2.0 * hue) < 1.0)
        res = f2;
    else if ((3.0 * hue) < 2.0)
        res = f1 + (f2 - f1) * ((2.0 / 3.0) - hue) * 6.0;
    else
        res = f1;
    return res;
}
vec3 hsl2rgb(vec3 hsl) {
    vec3 rgb;
    
    if (hsl.y == 0.0) {
        rgb = vec3(hsl.z); // Luminance
    } else {
        float f2;
        
        if (hsl.z < 0.5)
            f2 = hsl.z * (1.0 + hsl.y);
        else
            f2 = hsl.z + hsl.y - hsl.y * hsl.z;
            
        float f1 = 2.0 * hsl.z - f2;
        
        rgb.r = hue2rgb(f1, f2, hsl.x + (1.0/3.0));
        rgb.g = hue2rgb(f1, f2, hsl.x);
        rgb.b = hue2rgb(f1, f2, hsl.x - (1.0/3.0));
    }   
    return rgb;
}

vec2 texNormalMap(in vec2 _uv,float _time)
{
    vec2 s = 1.0/resolution.xy;
    float textureOffset=1.0;
    vec2 uv = _uv;
    float p = cnoise(vec3(uv,_time));
    float h1 = cnoise(vec3(uv + s * vec2(textureOffset,0),_time));
    float v1 =  cnoise(vec3(uv + s * vec2(0,textureOffset),_time));
       
    return (p - vec2(h1, v1));
}

vec2 pixelate(vec2 _uv,float _pixelSize)
{
    return floor(_uv / _pixelSize) * _pixelSize;
}

float blendSoftLight(float base, float blend) {
	return (blend<0.5)?(2.0*base*blend+base*base*(1.0-2.0*blend)):(sqrt(base)*(2.0*blend-1.0)+2.0*base*(1.0-blend));
}


vec3 blendSoftLight(vec3 base, vec3 blend) {
	return vec3(blendSoftLight(base.r,blend.r),blendSoftLight(base.g,blend.g),blendSoftLight(base.b,blend.b));
}

vec3 blendSoftLight(vec3 base, vec3 blend, float opacity) {
	return (blendSoftLight(base, blend) * opacity + base * (1.0 - opacity));
}
float blendOverlay(float base, float blend) {
	return base<0.5?(2.0*base*blend):(1.0-2.0*(1.0-base)*(1.0-blend));
}

vec3 blendOverlay(vec3 base, vec3 blend) {
	return vec3(blendOverlay(base.r,blend.r),blendOverlay(base.g,blend.g),blendOverlay(base.b,blend.b));
}

vec3 blendOverlay(vec3 base, vec3 blend, float opacity) {
	return (blendOverlay(base, blend) * opacity + base * (1.0 - opacity));
}
float luma(vec3 color) {
    return dot(color, vec3(0.299, 0.587, 0.114));
  }
  
  float luma(vec4 color) {
    return dot(color.rgb, vec3(0.299, 0.587, 0.114));
  }
  

void main()	{
    float step = 1.0/300.0;
    vec2 uv = gl_FragCoord.xy / resolution.xy;
    vec3 rgb = texture(tex0,pixelate(uv,0.1)).rgb;

    float pnoise = cnoise(vec3(uv,time))*TWO_PI;

    vec2 uv_r = uv;
    uv_r.x += cos(pnoise+uv.y*20.0)*step;
    uv_r.y += sin(time+pnoise+uv.y*20.0)*step;

    float red_channel = texture(tex0,uv_r).r;
    vec2 uv_g = uv;
    uv_g.x += cos(pnoise+uv.y*21.0)*step;
    uv_g.y += sin(time+pnoise+uv.y*20.0)*step;
    float green_channel = texture(tex0,uv_g).g;
    float circle = 1.0-length(uv-vec2(u_pointer.xy));
    circle = smoothstep(0.9,1.0,circle)*(u_pointer.z >= 0.0 ? 1.0 : 0.0);

    vec2 uv_b = uv;
    float time_b = time*0.01;
   // step*=(mix(1.0,10.0,circle));

    uv_b.x += cos(time_b+pnoise)*step;
    uv_b.y += sin(time_b+pnoise)*step;

    float sdsdsd = dot(normalize(u_pointer.xy),normalize(uv));
 //   uv_b.x += cos(sdsdsd)*circle;
   // uv_b.y += sin(sdsdsd)*circle;

    float blue_channel = texture(tex0,uv_b).b;
    vec4 outputrgb = texture(tex0,uv_b);
    //outputrgb.rgb+=(hsl2rgb(vec3(pnoise,1.0,0.5))*step);

    vec4 color_circle = vec4(hsl2rgb(vec3(mix(0.5,.9,mod((circle+time*.9),1.0)),1.0,0.5)),circle);
    //outputrgb.rgb=max(outputrgb.rgb,mix(outputrgb.rgb,color_circle.rgb,circle));

    outputrgb.rgb=mix(outputrgb.rgb,hsl2rgb(vec3(mix(0.5,1.0,mod((circle+time*.9),1.0)),1.0,.5)),circle*.1);
    outputrgb.rgb=mix(outputrgb.rgb,vec3(luma(outputrgb.rgb)),step);

    gl_FragColor =outputrgb;//vec4(red_channel,green_channel,blue_channel,1.0);

}

`


const   outputFragmentShader = `
uniform sampler2D   tex0;
uniform float time;


void main()	{

    vec2 uv = gl_FragCoord.xy / resolution.xy;

    gl_FragColor =texture(tex0,uv);

}

`

var dtBuffer = gpuCompute.createTexture()
const theArray = dtBuffer.image.data;
for ( let k = 0, kl = theArray.length; k < kl; k += 4 ) {

    const x = Math.floor((k/4)%WIDTH)
    const y = Math.floor((k/4)/WIDTH)
    const z = Math.random();

    theArray[ k + 0 ] = Math.random()//x/WIDTH;
    theArray[ k + 1 ] =  theArray[ k + 0 ];
    theArray[ k + 2 ] = theArray[ k + 0 ]
    theArray[ k + 3 ] = 1;

}

var filtro = gpuCompute.createShaderMaterial( outputFragmentShader, { tex0: { value: null } } );
filtro.uniforms.tex0.value = dtBuffer;

var myRenderTarget = gpuCompute.createRenderTarget();
myRenderTarget.wrapS = THREE.RepeatWrapping
myRenderTarget.wrapT = THREE.RepeatWrapping
myRenderTarget.minFilter = THREE.NearestFilter
myRenderTarget.magFilter = THREE.NearestFilter

gpuCompute.doRenderTarget( filtro, myRenderTarget );

var filtroFeedback = gpuCompute.createShaderMaterial( fragmentNoiseShader, { tex0: { value: null } } );
filtroFeedback.uniforms.tex0.value = myRenderTarget;
filtroFeedback.uniforms.time = {value:0.0};
filtroFeedback.uniforms.u_pointer = {value:[0.0,0.0,-1.0]}
var myRenderTarget2 = gpuCompute.createRenderTarget();
myRenderTarget2.wrapS = THREE.RepeatWrapping
myRenderTarget2.wrapT = THREE.RepeatWrapping
myRenderTarget2.minFilter = THREE.NearestFilter
myRenderTarget2.magFilter = THREE.NearestFilter


const error = gpuCompute.init();
if ( error !== null ) {
    console.error( error );
}


///bufferUniforms['texDisplace'] = {value:gpuCompute.getCurrentRenderTarget( noiseVariables ).texture}


document.body.appendChild( renderer.domElement );
//function to listen mouse and touch input

canvas.addEventListener('pointerdown', handleTouchMove);
canvas.addEventListener('pointermove', handleTouchMove);
canvas.addEventListener('pointerup', handleTouchMove);
canvas.addEventListener('pointerout', handleTouchMove);

function handleTouchStart(e) {
  e.preventDefault();
}

function handleTouchMove(e) {
    if(e.type === 'pointerdown'){
        pointer.down = 0.0;
    }else    if(e.type === 'pointerup' || e.type === 'pointerout'){
        pointer.down = -1.0;
    }
    pointer.x=e.clientX/canvas.width
    pointer.y=1.0-(e.clientY/canvas.height)
    
}




const material = new THREE.ShaderMaterial({
    vertexShader: `
      varying vec2 vUv;
      
      void main() {
          vUv = uv;
          gl_Position = vec4( position, 1.0 );    
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
      uniform sampler2D tex0;
      void main() {
          gl_FragColor = texture2D(tex0, vUv);
      }
    `
  });
material.uniforms.tex0 = {value:myRenderTarget2.texture}
const quad = new THREE.Mesh( new THREE.PlaneBufferGeometry( 2, 2, 1, 1 ), material );
scene.add( quad );


requestAnimationFrame(tick)

var prev = null
var steps = 0;
function    tick()
{
    var time = clock.getElapsedTime()
    var delta = clock.getDelta()
    var deltaTime = time-prev
    prev = time
    
    //bufferUniforms[ 'time' ].value = time;

    //noiseUniforms[ 'time' ].value = time;
    filtroFeedback.uniforms.u_pointer = {value:[pointer.x,pointer.y,pointer.down]};

    filtroFeedback.uniforms.time = {value:time};
    gpuCompute.doRenderTarget( filtroFeedback, myRenderTarget2 );

  //  bufferUniforms[ 'delta' ].value = deltaTime;
  //  gpuCompute.compute();

//    bufferUniforms[ 'tex0' ].value = gpuCompute.getCurrentRenderTarget( bufferVariable ).texture;
    filtro.uniforms.tex0.value = myRenderTarget2;
    gpuCompute.doRenderTarget( filtro, myRenderTarget );


    steps++;
    //controls.update()
    renderer.render(scene,camera)
    requestAnimationFrame(tick)
}


addEventListener('resize',()=>{
    camera.aspect = innerWidth/innerHeight
    camera.updateProjectionMatrix()
    renderer.setSize(innerWidth,innerHeight)
});
